import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { History } from 'history';
import { BaseCategory, Category } from '../interface/category.types';
import { Activity, BaseActivity } from '../interface/activity.types';
import React, { createContext, FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SSOData, useAuth } from './auth-provider';
import { ApiFile, ApiReferential, RequestPagination } from '../interface/api.types';
import { BaseQuestion, BaseQuestionChoices, Question, QuestionChoices } from '../interface/question.types';
import { BaseChoice, Choice } from '../interface/choice.types';
import { RemoveItemInObject } from '../utils/deep-object.utils';
import { FormParams, FormScope, ScopeQuestion, Scopes, ScopeType } from '../interface/scope.types';
import { EstimateParam } from '../interface/estimate-param.types';
import { Company } from '../interface/company.types';
import { DocumentParam } from '../interface/document-param.types';
import {
  DocumentType,
  ListQuoteDocuments,
  QuoteDocument,
  ReferentialCustomer,
  ReferentialCustomerList,
} from '../interface/document.type';
import { Product, ReferentialProduct, ReferentialProductList } from '../interface/product.types';
import { Estimation } from '../interface/estimation.type';
import { Locale } from './intl.provider';
import { useIntl } from 'react-intl';

const extractActivityId = (activity: Activity): BaseActivity => {
  return {
    ...RemoveItemInObject(activity, 'id'),
    questions: activity.questions.map(q => extractQuestionId(q)),
  };
};
export const BASE_QUESTION: Question = {
  id: 'NEW',
  label: '',
  type: 'SHORT_TEXT',
  description: '',
  mandatory: false,
};
const extractQuestionId = (question: Question | QuestionChoices): BaseQuestion | BaseQuestionChoices => {
  const extractId = RemoveItemInObject(question, 'id');
  return (extractId as QuestionChoices).choices ? {
    ...extractId,
    choices: (extractId as QuestionChoices).choices.map(q => extractChoiceId(q)),
  } : extractId as Question;
};
const extractChoiceId = (choice: Choice): BaseChoice => {
  return RemoveItemInObject(choice, 'id');
};

interface RequestOptions {
  axiosConfig?: AxiosRequestConfig;
  body?: any;
  noRedirectAfterError?: boolean;
  withoutCodeBouton?: boolean;
}

interface EnvData {
  api: string;
  ssoLogin: string;
  ssoCheck: string;
  ssoScope: string;
  formUrl: string;
}
export class ApiRequest {
  constructor(private history?: History<unknown>, private auth?: SSOData, private env?: EnvData, private locale?: string) {
  }

  async createCustomer(body: Omit<ReferentialCustomer, 'id'>): Promise<ReferentialCustomer> {
    return this.sendRequest('post', '/customers', {
      body
    });
  }

  async createDocument(doc: Omit<QuoteDocument, 'id'>): Promise<QuoteDocument> {
    return this.sendRequest('post', '/documents', { body: doc });
  }

  async createProduct(body: ReferentialProduct): Promise<ReferentialProduct> {
    return this.sendRequest('post', '/products', {
      body
    });
  }

  async deleteActivity(categoryId: string, activityId: string): Promise<Activity[]> {
    return this.sendRequest('delete', `/estimation/category/${categoryId}/activity/${activityId}`);
  }

  async deleteCategory(categoryId: string): Promise<Category[]> {
    return this.sendRequest('delete', '/estimation/category/' + categoryId);
  }

  async deleteChoice(categoryId: string, activityId: string, questionId: string, choiceId: string): Promise<Choice[]> {
    return this.sendRequest('delete', `/estimation/category/${categoryId}/activity/${activityId}/question/${questionId}/choice/${choiceId}`);
  }

  async deleteDocument(documentId: string): Promise<QuoteDocument> {
    return this.sendRequest('delete', `/documents/${documentId}`);
  }

  async deletePicture(filename: string): Promise<any> {
    return this.sendRequest('delete', `/picture/${filename}`, { noRedirectAfterError: true });
  }

  async deleteQuestion(categoryId: string, activityId: string, questionId: string): Promise<Question[]> {
    return this.sendRequest('delete', `/estimation/category/${categoryId}/activity/${activityId}/question/${questionId}`);
  }

  async deleteScopeQuestion(scope: ScopeType, question: ScopeQuestion): Promise<ScopeQuestion[]> {
    return this.sendRequest('delete', `/estimation/scope/${scope}/question/${question.id}`);
  }

  async getActivity(categoryId: string, activityId: string): Promise<Activity> {
    return this.sendRequest('get', `/estimation/category/${categoryId}/activity/${activityId}`);
  }

  async getFormScopes(formId: string): Promise<Scopes> {
    return this.sendRequest('get', `/estimation/form/${formId}/scope`);
  }

  async getCategories(): Promise<Category[]> {
    return this.sendRequest('get', '/estimation/category');
  }

  async getChoice(categoryId: string, activityId: string, questionId: string, choiceId: string): Promise<Choice> {
    return this.sendRequest('get', `/estimation/category/${categoryId}/activity/${activityId}/question/${questionId}/choice/${choiceId}`);
  }

  async getCompany(): Promise<Company> {
    return this.sendRequest('get', '/company');
  }

  async getCustomer(id: string): Promise<ReferentialCustomer> {
    return this.sendRequest('get', `/customers/${id}`, { noRedirectAfterError: true });
  }

  async getCustomers(params: { company?: string, name?: string, limit?: string, offset?: string } = {}): Promise<RequestPagination & ReferentialCustomerList> {
    return this.sendRequest('get', '/customers', { axiosConfig: { params }, noRedirectAfterError: true });
  }

  async getDocumentParam(): Promise<DocumentParam> {
    return this.sendRequest('get', '/parameters');
  }

  async getDocuments(params: { limit?: number, offset?: number, type?: DocumentType, search?: string } = {}): Promise<ListQuoteDocuments> {
    return this.sendRequest('get', '/documents', { axiosConfig: { params } });
  }

  async getEstimateParam(): Promise<EstimateParam> {
    return this.sendRequest('get', '/estimation/param');
  }

  async getEstimation(estimationId: string): Promise<Estimation> {
    return this.sendRequest('get', `/estimation/${estimationId}`, { noRedirectAfterError: true });
  }

  async getPDF(docId: string): Promise<any> {
    return this.sendRequest('get', `/documents/${docId}/pdf`, { axiosConfig: { responseType: 'blob' } });
  }

  async getProduct(productCode: string): Promise<ReferentialProduct> {
    return this.sendRequest('get', `/products/${productCode}`, { noRedirectAfterError: true });
  }

  async getProducts(params: { productCode?: string, name?: string, limit?: string, offset?: string } = {}): Promise<RequestPagination & ReferentialProductList> {
    return this.sendRequest('get', '/products', { axiosConfig: { params }, noRedirectAfterError: true });
  }

  async getQuestion(categoryId: string, activityId: string, questionId: string): Promise<Question> {
    return this.sendRequest('get', `/estimation/category/${categoryId}/activity/${activityId}/question/${questionId}`);
  }

  async getReferential(): Promise<ApiReferential[]> {
    return this.sendRequest('get', '/referential/category', { withoutCodeBouton: true });
  }

  async postActivity(categoryId: string, activity: Activity): Promise<Activity> {
    return this.sendRequest('post', `/estimation/category/${categoryId}/activity`,
      { body: extractActivityId(activity) });
  }
  async postDuplicateActivity(categoryId: string, activityId: string): Promise<Activity> {
    return this.sendRequest('post', `/estimation/category/${categoryId}/activity/${activityId}`,
      { body: {} });
  }

  async postCategory(category: BaseCategory): Promise<Category> {
    return this.sendRequest('post', '/estimation/category', { body: category });
  }
  async postDuplicateCategory(category: {   name: string,  categoryId?: string }): Promise<Category> {
    return this.sendRequest('post', `/estimation/category/${category.categoryId}`, { body: {name: category.name}, axiosConfig: {timeout:1} });
  }

  async postCategoryReferential(referential: ApiReferential): Promise<Category> {
    return this.sendRequest('post', '/estimation/category', { body: referential });
  }

  async postChoice(categoryId: string, activityId: string, questionId: string, choice: Choice): Promise<Choice> {
    return this.sendRequest('post',
      `/estimation/category/${categoryId}/activity/${activityId}/question/${questionId}/choice`,
      { body: extractChoiceId(choice) });
  }

  async postFile(binary: File, route: string): Promise<ApiFile> {
    const form = new FormData();
    form.append('file', binary);
    return this.sendRequest('post', route, {
      body: form, axiosConfig: {
        headers: {
          accept: 'application/json',
          'Content-Type': `multipart/form-data`,
        }
      },
    });
  }

  async postQuestion(categoryId: string, activityId: string, question: Question): Promise<Question> {
    return this.sendRequest('post',
      `/estimation/category/${categoryId}/activity/${activityId}/question`,
      { body: extractQuestionId(question) });
  }

  async putActivity(categoryId: string, activity: Activity): Promise<Activity> {
    return this.sendRequest('put', `/estimation/category/${categoryId}/activity/${activity.id}`, { body: activity });
  }

  async putCategory(category: Category): Promise<Category> {
    return this.sendRequest('put', `/estimation/category/${category.id}`, { body: category });
  }

  async putChoice(categoryId: string, activityId: string, questionId: string, choice: Choice): Promise<Choice> {
    return this.sendRequest('put',
      `/estimation/category/${categoryId}/activity/${activityId}/question/${questionId}/choice/${choice.id}`,
      { body: choice });
  }

  async putCompany(company: Company): Promise<Company> {
    return this.sendRequest('put', '/company', { body: company });
  }

  async patchCompany(update: Partial<Company>): Promise<Company> {
    return this.sendRequest('patch', '/company', {body: update})
  }

  async putDocumentParam(param: DocumentParam): Promise<DocumentParam> {
    return this.sendRequest('put', '/parameters', { body: param });
  }

  async putEstimateParam(param: EstimateParam): Promise<EstimateParam> {
    return this.sendRequest('put', '/estimation/param', { body: param });
  }

  async putEstimationLocale(locale: Locale): Promise<{ locale: Locale }> {
    return this.sendRequest('put', '/estimation/param/locale', { body: { locale } });
  }

  async putQuestion(categoryId: string, activityId: string, question: Question): Promise<Question> {
    return this.sendRequest('put',
      `/estimation/category/${categoryId}/activity/${activityId}/question/${question.id}`,
      { body: question });
  }
  async getAllForms(): Promise<FormScope[]>{
    return this.sendRequest('get',`/estimation/form`)
  }

  async createForm(form: Omit<FormScope, 'id'>): Promise<FormScope>{
    return this.sendRequest('post', '/estimation/form', {body: form})
  }

  async putScope(formId: string,scope: ScopeType, questions: ScopeQuestion[]): Promise<ScopeQuestion[]> {
    return this.sendRequest('put', `/estimation/form/${formId}/scope/${scope}`, { body: questions });
  }

  async putFormParams(formId: string, body: FormParams): Promise<FormParams> {
    return this.sendRequest('put', `/estimation/form/${formId}/params`, { body })
  }

  async putScopeQuestion(scope: ScopeType, question: ScopeQuestion): Promise<ScopeQuestion> {
    return this.sendRequest('put',
      `/estimation/scope/${scope}/question/${question.id}`, { body: question });
  }

  async putScopeSetting(formId: string,scope: ScopeType, setting: ScopeQuestion): Promise<ScopeQuestion> {
    return this.sendRequest('put',
      `/estimation/form/${formId}/scope/${scope}/settings/${setting.id}`, { body: setting });
  }

  async retrieveDocument(docId: string): Promise<QuoteDocument> {
    return this.sendRequest('get', `/documents/${docId}`);
  }

  async scopeQuestion(scopeType: ScopeType, questionId: string): Promise<Question> {
    return this.sendRequest('get', `/estimation/scope/${scopeType}/question/${questionId}`);
  }

  async simulateDocument(draft: Omit<QuoteDocument, 'id' | 'parameters' | 'sender' | 'total' | 'recipient' | 'expirationDate' | 'issueDate'>): Promise<QuoteDocument> {
    return this.sendRequest('post', '/drafts/document',
      { body: draft, noRedirectAfterError: true });
  }

  async simulateProduct(draft: Omit<Product, 'productCode' | 'name'>): Promise<Product> {
    return this.sendRequest('post', '/drafts/item', { body: draft, noRedirectAfterError: true });
  }

  async updateCustomer(body: ReferentialCustomer): Promise<ReferentialCustomer> {
    return this.sendRequest('put', `/customers/${body.id}`, {
      body,
    });
  }

  async updateDocument(doc: QuoteDocument): Promise<QuoteDocument> {
    const body = doc;
    if (doc.recipient.customerId === null) {
      delete body.recipient.customerId;
    }
    return this.sendRequest('put', `/documents/${doc.id}`, { body });
  }

  async updateProduct(body: ReferentialProduct): Promise<ReferentialProduct> {
    return this.sendRequest('put', `/products/${body.code}`, {
      body
    });
  }

  private formatConfig(axiosConfig: AxiosRequestConfig = {}): AxiosRequestConfig {
    const copyParams = { ...axiosConfig.params };
    for (const paramsKey in copyParams) {
      if (Object.prototype.hasOwnProperty.call(copyParams, paramsKey)) {
        if (copyParams[paramsKey] === '') {
          delete copyParams[paramsKey];
        }
        if (typeof copyParams[paramsKey] === 'undefined') {
          delete copyParams[paramsKey];
        }
        if (copyParams[paramsKey] === null) {
          delete copyParams[paramsKey];
        }
      }
    }
    if (!this.auth) {
      return { ...axiosConfig, params: copyParams };
    }
    return {
      ...axiosConfig,
      params: copyParams,
      headers: {
        Authorization: 'Bearer ' + this.auth.token,
        'Accept-Language': this.locale || 'fr-FR',
        ...axiosConfig.headers,
      },
    };
  }

  private async sendRequest<T>(type: 'get' | 'delete' | 'post' | 'put' | 'patch', route: string, options: RequestOptions = {}): Promise<T> {
    if (!this.env || !this.auth) {
      return Promise.reject();
    }
    const code = options.withoutCodeBouton ? '' : '/' + this.auth.codeBouton;
    let result;
    const onError = (error: AxiosError) => {
      if (options.noRedirectAfterError) {
        console.error(error)
      } else {
        if (error.response?.status === 403 || error.response?.status === 401) {
          if (!window) {
            throw error;
          }
          const redirect = window.confirm('Session invalid, would you like to be redirect ?');
          if (redirect) {
            if (!this.env) {
              throw error;
            }
            window.location.href = this.env.ssoLogin;
            throw error;
          }
        } else {
          throw error;
        }
      }
    };
    switch (type) {
      case 'get':
      default:
        result = await axios.get<T>(`${this.env.api}${code}${route}`, this.formatConfig(options.axiosConfig)).catch(onError);
        break;
      case 'delete':
        result = await axios.delete<T>(`${this.env.api}${code}${route}`, this.formatConfig(options.axiosConfig)).catch(onError);
        break;
      case 'post':
        result = await axios.post<T>(`${this.env.api}${code}${route}`, options.body, this.formatConfig(options.axiosConfig)).catch(onError);
        break;
      case 'put':
        result = await axios.put<T>(`${this.env.api}${code}${route}`, options.body, this.formatConfig(options.axiosConfig)).catch(onError);
        break;
      case 'patch':
        result = await axios.patch<T>(`${this.env.api}${code}${route}`, options.body, this.formatConfig(options.axiosConfig)).catch(onError);
        break;
    }
    return result ? result.data : Promise.reject();
  }
}

const ApiContext = createContext<ApiRequest>(new ApiRequest());
export const useApi = () => useContext(ApiContext);

export const ApiProvider: FC<EnvData> = props => {
  const { children, api, ssoLogin, ssoCheck, ssoScope, formUrl } = props;
  const history = useHistory();
  const auth = useAuth();
  const { locale } = useIntl();
  const env = {
    api,
    ssoLogin,
    ssoCheck,
    ssoScope,
    formUrl
  }
  return <ApiContext.Provider
    value={new ApiRequest(history, auth, env, locale)}>
    {children}
  </ApiContext.Provider>;
};
