import { FC } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from '@ckeditor/ckeditor5-core';
import styled from "styled-components";
import { getTypo } from "@linkeo.com/ui-lib-react";
import DOMPurify from 'dompurify';

const EditorContainer = styled.div`
    ${getTypo('input')};

    .ck.ck-editor__editable {
        height: 150px;
    }

    .ck.ck-editor__editable.ck-focused:not(.ck-editor_nested-editable) {
    border: 1px solid ${({ theme }) => theme.colors.grey60};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }

   .ck {
    border-radius: 4px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.grey95};
    color: ${({ theme }) => theme.colors.grey20};
    transition: border-color ease-in-out 200ms, box-shadow ease-in-out 200ms;

    &:hover {
        border-color: ${({ theme }) => theme.colors.grey60};
        }
    }

    .ck.ck-editor__editable.ck-focused:not(.ck-editor_nested-editable) {
    border: 1px solid ${({ theme }) => theme.colors.grey60};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
`;

interface TextEditorProps {
    locale: string;
    value: string;
    onChange: (value: string) => void;
}

export const TextEditor: FC<TextEditorProps> = ({ locale, value, onChange }) => {

    const editorConfig = {
        toolbar: {
            items: ['undo', 'redo', '|', 'selectAll', '|', 'bold', 'italic', '|', 'link'],
            shouldNotGroupWhenFull: false
        },
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
        },
        placeholder: '',
        language: locale.substring(0, 2),
    };

    return <EditorContainer>
        <CKEditor
            config={editorConfig}
            editor={ClassicEditor}
            data={value}
            onChange={(_, editor: Editor) => {
                const data = DOMPurify.sanitize(editor.getData());
                onChange(data);
            }}
        />
    </EditorContainer>
}