import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DraggableCard } from '../draggable-card';
import { DragPosition, DragStartInfo } from '../../common';

const DRAGGABLE_PADDING_Y = 20;

export interface DraggableListItem<T> {
  canRemove?: boolean;
  canToggle?: boolean;
  custom?: ReactNode;
  data: T;
  displayModify?: boolean;
  id: string;
  subtitle?: string;
  htmlSubtitle?: string;
  title: string;
  toggleState?: boolean;
}

export interface DraggableListProps<T> {
  isCollapsed?: boolean;
  items: DraggableListItem<T>[];
  onChange: (items: DraggableListItem<T>[]) => void;
  onDelete?: (item: DraggableListItem<T>) => void;
  onEdit?: (item: DraggableListItem<T>) => void;
  onToggle?: (item: DraggableListItem<T>) => void;
}

const Container = styled.div`
  position: relative;
`;
const switchItems = function<T>(arr: T[], from: number, to: number): T[] {
  if (to < 0) {
    return arr;
  }
  const copy = arr.slice();
  copy.splice(to, 0, copy.splice(from, 1)[0]);
  return copy;
};

interface ItemsInfo<T> {
  isDragged: boolean;
  item: DraggableListItem<T>;
}

interface DraggedElement {
  element: HTMLDivElement;
  offset: DragPosition;
}

export function DraggableList<T>(props: DraggableListProps<T>) {
  const { onChange, items, onEdit, isCollapsed, onToggle, onDelete } = props;
  const containerRef = useRef<HTMLDivElement>();
  const draggedElementRef = useRef<DraggedElement | undefined>();
  const lockRef = useRef<boolean>(false);
  const buildItemsInfo = useCallback((arr: DraggableListItem<T>[]): ItemsInfo<T>[] => {
    return arr.map((item, index) => ({
      item,
      isDragged: false,
      initialIndex: index,
    }));
  }, []);
  const [getItemsInfo, setItemsInfo] = useState<ItemsInfo<T>[]>(buildItemsInfo(items));

  useEffect(() => {
    setItemsInfo(buildItemsInfo(items));
  }, [items, buildItemsInfo]);
  const emitChange = (itemInfo: ItemsInfo<T>[]) => {
    onChange(itemInfo.map(info => info.item));
  };
  const onDragEnd = () => {
    if (lockRef.current) {
      return;
    }
    lockRef.current = true;
    setTimeout(() => {
      lockRef.current = false;
    }, 30);
    draggedElementRef.current = undefined;
    const unDragged = getItemsInfo.map(info => {
      return {
        ...info,
        isDragged: false,
      };
    });
    setItemsInfo(unDragged);
    emitChange(unDragged);
  };
  const onMoveItem = (index: number, direction: number) => {
    emitChange(switchItems(getItemsInfo, index, index + direction));
  };
  const onDragStart = (index: number, dragInfo: DragStartInfo) => {
    setItemsInfo(
      getItemsInfo.map((itemInfo, i) => {
        if (index !== i) {
          return itemInfo;
        }
        return {
          ...itemInfo,
          isDragged: true,
        };
      })
    );
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const children = container.children;
    if (!children) {
      return;
    }
    const child = children[index].firstChild;
    if (!child) {
      return;
    }
    draggedElementRef.current = {
      element: child as HTMLDivElement,
      offset: dragInfo.offSet,
    };
  };
  const moveDragged = (event: React.DragEvent, dragged: DraggedElement) => {
    dragged.element.style.left = event.clientX - dragged.offset.x + 'px';
    dragged.element.style.top = event.clientY - dragged.offset.y + 'px';
  };
  const getDragPosition = (event: React.DragEvent): number | undefined => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const children = container.children;
    if (!children) {
      return;
    }
    const getMinMax = (element: HTMLDivElement): { min: number; max: number } => {
      const division = element.clientHeight / 4;
      const min = element.offsetTop + division - DRAGGABLE_PADDING_Y;
      const max = element.offsetTop + element.clientHeight - division;
      return {
        min,
        max,
      };
    };
    const rect = container.getBoundingClientRect();
    const mouseY = event.clientY - rect.top;
    const eligiblePos: number[] = [];
    for (let i = 0; i < children.length; i++) {
      const current = getMinMax(children[i] as HTMLDivElement);
      if (i === 0) {
        if (mouseY < current.min) {
          eligiblePos.push(0);
        }
      } else if (i === children.length - 1) {
        if (mouseY > current.max) {
          eligiblePos.push(children.length - 1);
        }
      } else {
        const previous = getMinMax(children[i - 1] as HTMLDivElement);
        const next = getMinMax(children[i + 1] as HTMLDivElement);
        if (mouseY > previous.max && mouseY < current.min) {
          eligiblePos.push(i - 1);
        } else if (mouseY > current.max && mouseY < next.min) {
          eligiblePos.push(i);
        }
      }
    }
    return eligiblePos[0];
  };
  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    const dragged = draggedElementRef.current;
    if (!dragged) {
      return;
    }
    moveDragged(event, dragged);
    const pos = getDragPosition(event);
    if (typeof pos === 'undefined') {
      return;
    }
    const indexDragged = getItemsInfo.findIndex(info => info.isDragged);
    if (pos === indexDragged) {
      return;
    }
    setItemsInfo(switchItems(getItemsInfo, indexDragged, pos));
  };
  return (
    <Container
      ref={containerRef as any}
      onDragExit={onDragEnd}
      onDrop={onDragEnd}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
    >
      {getItemsInfo.map((itemInfo, index) => (
        <DraggableCard
          key={itemInfo.item.id}
          index={index}
          isDragged={itemInfo.isDragged}
          subtitle={itemInfo.item.subtitle}
          htmlSubtitle={itemInfo.item.htmlSubtitle}
          title={itemInfo.item.title}
          onDragStart={event => onDragStart(index, event)}
          onMove={event => onMoveItem(index, event)}
          onDelete={itemInfo.item.canRemove && onDelete ? () => onDelete(itemInfo.item) : undefined}
          onEdit={itemInfo.item.displayModify && onEdit ? () => onEdit(itemInfo.item) : undefined}
          onToggle={itemInfo.item.canToggle && onToggle ? () => onToggle(itemInfo.item) : undefined}
          toggleState={itemInfo.item.toggleState}
          isCollapsed={isCollapsed}
          custom={itemInfo.item.custom}
        />
      ))}
    </Container>
  );
}
